import React from "react";
import styled from "styled-components";
import Imgix from "react-imgix";
import { breakpoints, fonts } from "../styles/variables";
import { ImgixParams } from "../types/types";

const StyledSliderImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledSliderImg = styled(Imgix)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const CopyRight = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px;
    font-size: 10px;
    font-family: ${fonts.helvetica.regular};
    text-align: right;
    color: white;
    opacity: 0.7;
    letter-spacing: 0.05em;
  }
`;

interface SliderImgProps {
  src: string;
  sizes: string;
  imgixParams: ImgixParams;
  title: string;
  copyright: string | null;
}

const SliderImg: React.FC<SliderImgProps> = ({
  src,
  sizes,
  imgixParams,
  title,
  copyright,
}) => {
  return (
    <StyledSliderImgWrapper>
      <StyledSliderImg
        htmlAttributes={{ alt: title }}
        key={src}
        src={src}
        sizes={sizes.length > 0 ? sizes : ""}
        imgixParams={imgixParams}
      />
      {copyright !== null && <CopyRight>{copyright}</CopyRight>}
    </StyledSliderImgWrapper>
  );
};

export default SliderImg;
