import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../styles/variables";

const StyledContent = styled.div`
  p {
    font-family: ${fonts.helvetica.regular};
    font-size: 18px;
    line-height: 1.4;
  }

  h1 {
    font-family: ${fonts.helvetica.black.condensed};
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fonts.helvetica.bold};
  }

  img {
    max-width: 100%;
  }

  table {
    width: 100%;

    * {
      vertical-align: top;
      border-collapse: collapse;
      text-align: left;
      padding: 0;
    }

    th {
      font-family: ${fonts.helvetica.bold};
      font-weight: 900;
    }

    th,
    td {
      padding-left: 15px;

      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  a {
    text-decoration: underline;
    color: ${colors.blau};

    &:hover {
      text-decoration: none;
      color: ${colors.blau};
    }
  }

  b,
  strong {
    font-family: ${fonts.helvetica.bold};
    font-weight: 900;
  }

  figure {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.05em;

    img {
      margin-bottom: 7px;
    }
    figurecaption {
      margin: 0;
    }
  }
`;

interface ContentProps {
  content: String;
}

const Content: React.FC<ContentProps> = ({ content }) => {
  return (
    <StyledContent
      id="___gatsby"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Content;
