import * as React from "react";
import styled from "styled-components";

import arrowLeft from "../assets/arrow_left.svg";
import arrowRight from "../assets/arrow_right.svg";

const ArrowWrapperPrev = styled.div`
  position: absolute;
  top: calc(50% - 60px);
  left: 20px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  ""width: 10vw;
  height: 10vw;
  drop-shadow: 1px 1px 1px rgba(0, 0, 0, 1);

  &:active {
    transform: scale(1.1);
  }
`;

const ArrowWrapperNext = styled(ArrowWrapperPrev)`
  left: auto;
  right: 20px;
  justify-content: flex-end;
`;

const ArrowIcon = styled.img`
  height: 120px;
`;

const SliderOptions = {
  grabCursor: true,
  speed: 2000,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class=${className} style="background-color:white;width:15px;height:15px;opacity:1;margin-right:7px;margin-left:7px;"></span>`;
    },
  },
  navigation: {
    nextEl: ".custom-swiper-button-next",
    prevEl: ".custom-swiper-button-prev",
  },
  renderPrevButton: () => (
    <ArrowWrapperPrev className="custom-swiper-button-prev">
      <ArrowIcon src={arrowLeft}></ArrowIcon>
    </ArrowWrapperPrev>
  ),
  renderNextButton: () => (
    <ArrowWrapperNext className="custom-swiper-button-next">
      <ArrowIcon src={arrowRight}></ArrowIcon>
    </ArrowWrapperNext>
  ),
};

export default SliderOptions;
