import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/de-ch";

const DateFromTo = styled.span``;

interface DateFromToProps {
  datum: string | undefined;
  datumBis?: string | undefined;
}

const StyledDateFromTo: React.FC<DateFromToProps> = ({ datum, datumBis }) => {
  var datumY = moment(datum).format("YYYY");
  var datumM = moment(datum).format("MMMM");
  var datumD = moment(datum).format("DD");
  var dateFromTo = "";

  if (datumBis !== null) {
    if (datum != datumBis) {
      var datumBisY = moment(datumBis).format("YYYY");
      var datumBisM = moment(datumBis).format("MMMM");
      var datumBisD = moment(datumBis).format("DD");

      if (datumBisY === datumY) {
        datumY = "";
      }
      if (datumBisM === datumM) {
        datumM = "";
      }
      dateFromTo = `
          ${datumD}. ${datumM} ${datumY} – ${datumBisD}. ${datumBisM} ${datumBisY}`;
    } else {
      dateFromTo = moment(datum).format("dddd, DD. MMMM YYYY");
    }
  } else {
    dateFromTo = moment(datum).format("dddd, DD. MMMM YYYY");
  }

  return <DateFromTo>{dateFromTo}</DateFromTo>;
};
export default StyledDateFromTo;
