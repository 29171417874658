import * as React from "react";
import styled from "styled-components";
import { colors, fonts } from "../styles/variables";

const StyledButton = styled.button`
  font-family: ${fonts.helvetica.black.condensed};
  padding: 5px 13px;
  font-size: inherit;
  background-color: ${colors.blau};
  color: white;
  border: none;
  margin-top: 1em;
  cursor: pointer;

  &.blau {
    background-color: ${colors.blau};
  }
  &.hellblau {
    background-color: ${colors.hellblau};
  }
  &.orange {
    background-color: ${colors.orange};
  }
  &.gelb {
    background-color: ${colors.gelb};
    color: black;
  }

  &:hover {
    background-color: white;
    color: ${colors.blau};
  }

  @media print {
    display: none;
  }
`;

interface StyledStatusButtonProps {
  buttonfarbe?: string;
  buttontext?: string | null;
}

const StyledStatusButton: React.FC<StyledStatusButtonProps> = ({
  buttonfarbe = "blau",
  buttontext = "mehr",
}) => {
  return (
    <StyledButton className={buttonfarbe}>
      {buttontext != null ? buttontext : "mehr"}
    </StyledButton>
  );
};
export default StyledStatusButton;
